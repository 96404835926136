import * as React from "react";
import placeholder from "../images/placeholder.png"
import "../css/card.css"

const linkStyle = {
  color: "#25392C",
  fontWeight: 700,
  fontSize: 16,
  verticalAlign: "5%",
  textDecoration: 'none',
};

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 7,
  padding: "4px 6px",
  display: "inline-block",
  position: "absolute",
  top: 0,
  right: 0,
  marginLeft: 10,
  lineHeight: 1,
};

const descriptionStyle = {
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
  fontWeight: 400,
  color: '#707070',
  fontSize: '14px',
  paddingBottom: '8px',
};

const getCuisineType = (cuisinetype) => {
  return cuisinetype.split(',').join(', ')
}

const showDistance = (distance) => {
  if (distance != undefined) {
    return <p className="distance"> {distance} kms</p>
  }
}

const Card = (props) => {
  let { restaurantname, cuisinetype, vertical, miniapplink, logo, distance } = props.data;
  return (
    <a
      className="linkStyle"
      href={`${miniapplink}?utm_source=peppo-listing&utm_medium=website&utm_campaign=whatsapp`}
      target={"_blank"}
      rel="noreferrer"

    >
      <div className="cardContainer">
        <div className="cardSection">
          <div className="imageWrapper">
            <img className="image" src={logo || placeholder} alt={`${restaurantname} logo`}></img>
          </div>
          <div className="verticalContainer">
            <div className="cardSectionText">
              <div className="info">
                <a
                  className="linkStyle"
                  href={`${miniapplink}?utm_source=peppo-listing&utm_medium=website&utm_campaign=whatsapp`}
                  target={"_blank"}

                  rel="noreferrer"
                >
                  {restaurantname}

                </a>
                <p className="descriptionStyle" >{getCuisineType(cuisinetype)}</p>

              </div>
            </div>
            <div className="badgeStyle" >{vertical}</div>
            {showDistance(distance)}
          </div>
        </div>

      </div>
    </a>
  );
};

export default Card;

import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Card from "../components/Card";
import Dropdown from "../components/Dropdown";
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../hooks/use-sitemMetadata'
import "../css/home.css"
import logo from "../images/peppo_logo.png"
import graphics from '../images/graphics.png'
import mailIcon from '../images/mail.svg'
import callIcon from '../images/call.svg'



// styles

const headingAccentStyles = {
  color: "#088413",
  textAlign: 'center'
};
const paragraphStyles = {
  marginBottom: 48,
  fontWeight: 400,
  color: '#000',
  fontSize: '16px',
  paddingBottom: '8px',
  textAlign: 'center'
};

const listStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  paddingLeft: 0,
};

/* find the distance between 2 coorinates using Haversine’ formula 
  Ref: https://stackoverflow.com/a/27943
*/
const getDistanceFromLatLonInm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1);  // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = (R * c) * 1000; // Distance in m
  return d;
}

//method to convert deg to radius
const deg2rad = (deg) => {
  return deg * (Math.PI / 180)
}

const sortRestaurants = (restaurants) => {
  
  return restaurants.sort(function (a, b) { return a?.node?.distance - b?.node?.distance });
}

const mapRestaurants = (lat, long, restaurants) => {

  let lat2, long2, location, distance
  restaurants.map((data) => {

    location = data?.node.latlong.split(', ')
    lat2 = location[0]
    long2 = location[1]
    distance = getDistanceFromLatLonInm(lat, long, lat2, long2)
    data.node.distance = distance < 10000 ? (distance / 1000).toFixed(1) : parseInt(distance / 1000)

  })
  return sortRestaurants(restaurants)
}

// markup
const IndexPage = (props) => {
  const { title, description } = useSiteMetadata();
  let restaurants = [];
  let cuisineList = [];
  const urlParam = typeof document == "undefined" ? "" : document.location.search.substring(6).toLowerCase();
  if (urlParam !== "") {
    restaurants = props.data.allGoogleSheetReadyRow.edges.filter(val => {
      if (val.node.city.toLowerCase() == urlParam) {
        return val
      }
    })
  } else {
    restaurants = props.data.allGoogleSheetReadyRow.edges
  }
  cuisineList = props.data.allGoogleSheetReadyRow.edges.reduce((prev, curr) => { 
    const cuisineTypes = curr.node.cuisinetype.split(',')
      .map(item => item.trim())
      .filter(cuisine => !prev.includes(cuisine))
    return [...prev, ...cuisineTypes]
  }, []).map(item => ({ name: item, isChecked: false }))
  
  const [searchTerm, setSearchTterm] = useState("");
  const temp = 0;
  const [restaurantsList, setRestaurants] = useState(restaurants);
  const [selectedCuisineList, setSelectedCuisineList] = useState([]);



  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {

      
      if (coords) {
        
        const { latitude, longitude } = coords;
        console.log({ latitude, longitude });
        console.log("hello", mapRestaurants(latitude, longitude, restaurants))
        let final = mapRestaurants(latitude, longitude, restaurants)
        console.log('after sorting', final)
        setRestaurants([...final])
      } else {
        console.log('Unable to fetch location')
        setRestaurants(restaurants)
      }
    }, (error) => {
      console.log("location error", error);
      setRestaurants(restaurants)
    }
    )
  }, []);



  const showRestaurants = () => {
    const list = restaurantsList.filter(val => {
      if (selectedCuisineList.length > 0) {
        return selectedCuisineList.some(el => val.node.cuisinetype.includes(el.name)) ? val : null
      }
      else {
        return val
      }
    }).filter(val => {
        if (searchTerm == "") {
          return val;
        } else if (val.node.restaurantname.toLowerCase().includes(searchTerm.toLowerCase())) {
          return val;
        }
    });

    return list.length === 0 ? <div id="noMatch"><p >No matching restaurants found!</p></div> : list.map((data) => (
      <div key={data.id} className="listItemStyles">
        <Card data={data.node} ></Card>
      </div >
    ))
  };

  return (
    <main className="pageStyles">
      <Helmet>
        <html lang='en' />
        <title>{title}</title>
        <meta name='description' content={description} />
        <link rel="manifest" href="../public/manifest.webmanifest" />

      </Helmet>

      <div className="pad96">
        <div className="header">
          <a href="https://www.peppo.co.in/" target="_blank">
            <img className="image" src={logo} alt="peppo_logo"></img>
          </a>
          <a href="https://www.peppo.co.in/sign-up" target="_blank" className="cta">Take your kitchen online now 🚀</a>
        </div>
        <div style={{ display: 'block', marginTop: '40px', marginLeft: '50%' }}>
          <img className="image" src={graphics} alt="peppo_logo"></img>
        </div>
        <div className="mgT20"></div>
        <div className="whiteBox">
          <h1 className="headingStyles" style={headingAccentStyles}>
            Order
        <span style={headingAccentStyles}> from your favourite restaurants! </span>
            <span role="img" aria-label="Party popper emojis">
              🙏
        </span>
          </h1>
          <div style={paragraphStyles}>
          <p>Ordering food is an emotion.</p>
            <p>And when you place orders directly to your local restaurants, you not only beat your hunger, but also help them manage their kitchens profitably. You empower them as they can save on commissions to aggregators.</p>
            <p> Bonus, you get the best prices with high priority and the safest delivery.</p>
          <p>C'mon foodie, let's level up and <span style={{color: 'green'}}>#BeVocalForOurLocal</span></p>
          </div>
          <div className="searchInputWrapper">
            <Dropdown optionList={cuisineList} selectedList={selectedCuisineList} setSelectedList={setSelectedCuisineList} />
            <input
              className="searchBar"
              type="text"
              placeholder=" Search for any restaurant"
              onChange={(event) => {
                setSearchTterm(event.target.value);
              }}
            />
          </div>
          {
            !restaurantsList ? <div>Loading...</div> : <div style={listStyles}>


              {showRestaurants()}
            </div>}
        </div>
        <div className="footer">
          <div style={{ fontSize: '20px' }}>
            Want to talk to us regarding your order?
            <div className="contactWrapper">
              <div>
                <img src={mailIcon} alt="mail_icon"></img>
                <a className="hyperLink" href="mailto:help@peppo.co.in"> help@peppo.co.in</a>
              </div>
              <span> / </span>
              <div>
                <img src={callIcon} alt="call_icon"></img> <a className="hyperLink" href="tel:+919986574938">+919986574938</a>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '2rem', fontSize: '14px' }}>Copyright &copy; 2021, <span className="company"><a href="https://www.peppo.co.in/" target="_blank" rel="noreferrer">Peppo Technologies Pvt Ltd</a></span></div>
          <p>All rights reserved.</p>
        </div>
      </div>

    </main >

  );
};



export const query = graphql`
      query MyQuery {
        allGoogleSheetReadyRow {
        edges {
        node {
        restaurantname
          vertical
      cuisinetype
      id
      logo
      miniapplink
      latlong
      city
        }
      }
    }
  }
      `;
export default IndexPage;

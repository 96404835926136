import React, { useState } from "react";
import expandArrow from '../images/chevron-down.svg'
import "../css/dropdown.css"
import "../css/home.css"


const Dropdown = ({ optionList, selectedList, setSelectedList}) => {
    const [expand, setExpand] = useState(false);
    const [currentOptionList, setCurrentOptionList] = useState(optionList);


    const handleCheckboxItemClick = (event) => {
        let cuisines = [...currentOptionList]
        cuisines.forEach(cuisine => {
          if (cuisine.name === event.target.value) {
            cuisine.isChecked = event.target.checked
          }
        })
        setCurrentOptionList(cuisines)
      
      }
    
      const applyFilter = () => {
        let filteredList = currentOptionList.filter(option => option.isChecked)
        setSelectedList(filteredList)
        setExpand(false)
      }
    
      const clearAll = () => {
        let options = [...currentOptionList]
        options.forEach(option => option.isChecked = false)
        setSelectedList([])
        setExpand(false)
        setCurrentOptionList(options)
      }
      const clearOption = (name) => {
        let options = [...currentOptionList]
        options.forEach(option => {
          if (option.name === name) {
            option.isChecked = false
          }
        })
        setCurrentOptionList(options)
        applyFilter()
        
      }
      const toggleDropdown = (event) => {
        if (event.target.closest(".customDropdown")) {
          setExpand(!expand)
        }
      }

    return (
        <div className="searchBar customDropdown" onClick={toggleDropdown}>
              <div className="dropdownPlaceholder">{selectedList.length > 0 ? selectedList.map(option =>
                <div className="customChip">
                  {option.name}
                  <div onClick={(e) => {
                    e.stopPropagation();
                    clearOption(option.name);
                  }
                  }>&#x2715;</div>
                </div>) : "Select Cuisine"}</div>
              <div className={ expand ? "dropdownContent showDropdown": "dropdownContent"}>
                <div className="dropdownOptions" onClick={(e) => e.stopPropagation()}>
                {currentOptionList.map((option, idx) => 
                  <div className="checkboxInput">
                    <input type="checkbox" name="cusine" id={`cusine-type-${idx}`} checked={option.isChecked} onChange={handleCheckboxItemClick} value={option.name}/>
                    <label for={`cusine-type-${idx}`}>{option.name}</label>
                  </div>
                  )}
                </div>
                <div className="dropdownBtnWrapper">
                  <button className="baseButton" onClick={clearAll}>Clear All</button>
                  <button className="baseButton primaryButton" onClick={applyFilter}>Apply</button>
                </div>
              </div>
              <img className="selectIcon" src={expandArrow}/>
        </div>
    )
}


export default Dropdown;